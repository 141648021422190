@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap');


/* GLOBAL STYLES */
.is--pointer{
  cursor: pointer;
}

#root{
  overflow-x: hidden;
}

.c-img {
  width: 100%;
  height: auto;
}

html{
  scroll-behavior: smooth;
}

body{
  position: relative;
  background-color:#f4f4f4;
  background:#f4f4f4;
  overflow-x: hidden;
}

a {
text-decoration: none;
}

/* typography */
.t-h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  font-size: 44px;
  line-height: 1em;
  font-weight: 900;
}

.t-h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 900;
}

.t-h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 0.08em;
}

.t-h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 0.08em;
}

.t-h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.06em;
}

.t-h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 1em;
  font-weight: 400;
}

.t-logo {
  position: static;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0.06em;
  text-decoration: none;
}
/* typography colors */
.is--tc-white {
  color: #fff;
}

.is--tc-black {
  color: #0a0a1e;
}

/* layouts */
.l-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 72px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.l-section.is--short{
  padding-top: 32px;
  padding-bottom: 32px;
} 

.l-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1136px;
  margin-right: 48px;
  margin-left: 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* COMPONENTS */
/* Scene Card */
.c-scene-card{
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #e4e4e4;
}

/* To Top Button*/
.c-btn.is--top {
  display: inline-block;
  height: 50px;
  padding-right: 16px;
  padding-left: 16px;
  border-color: #0a0a1e;
  background-color: #0a0a1e;
  -webkit-transition: height 200ms ease-out;
  transition: height 200ms ease-out;
}

.c-btn.is--top:hover {
  background:#0a0a1e;
  height: 100px;
  color: #fff;
}


.top-btn_container{
  position:fixed;
  bottom: 0px;
  right: 0px;
  z-index: 20;
  margin-bottom: 24px;
  margin-right: 24px;
}

/* video list */
.c-video-list_grid {
  display: -ms-grid;
  display: grid;
  max-width: 860px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

/* people link large */
.c-lg-people-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a0a1e;
}

.c-lg-people-link:hover{
  text-decoration: none;
  color:#0a0a1e;
}

.c-lg-people-link:hover .is--cyan{
  height: 100%;
  width: 100%;
  left: 8%;
  top: -5%;
}

.c-lg-people-link:hover .is--red{
  height: 100%;
  width: 100%;
  left: -5%;
  top: -3%;
}

.c-lg-people-link:hover .is--green{
  height: 100%;
  width: 100%;
  left: 2%;
  top: -5%;
}

.c-lg-people-link:hover .is--yellow{
  height: 100%;
  width: 100%;
  left: 0%;
  top: 5%;
}

.c-lg-people-link_name{
  margin-top: 8px;
  text-align: center;
}

.c-lg-people-link_img-section {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  height: 120px;
  margin-bottom: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0.5px;
  border-color: #0a0a1e;
  border-radius: 100%;
}

.c-lg-people-link_img-container {
  position: relative;
  z-index: 2;
  width: 108px;
  height: 108px;
  border-radius: 100%;
  background-position: 50% 50%;
  background-size: cover;
}

.c-lg-people-link_color-bg {
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  right: 0%;
  bottom: 0%;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transition: height 200ms ease-out, width 200ms ease-out, right 200ms ease-out, bottom 200ms ease-out, left 200ms ease-out, top 200ms ease-out;
  transition: height 200ms ease-out, width 200ms ease-out, right 200ms ease-out, bottom 200ms ease-out, left 200ms ease-out, top 200ms ease-out;
}

.c-lg-people-link_color-bg.is--green {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #0f0;
}

.c-lg-people-link_color-bg.is--cyan {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #0ff;
}

.c-lg-people-link_color-bg.is--red {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #f00;
}

.c-lg-people-link_color-bg.is--yellow {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #ff0;
}

/* Section */
.c-section_title {
  margin-bottom: 32px;
}

.c-section_wrapper {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

/* Cateogry Card */
.c-category-card{
  position:relative;
  justify-self: stretch;
  -webkit-transition: transform 200ms ease-out;
  transition: transform 200ms ease-out;
}

.c-category-card:hover{
  transform: scale(1.05);
  z-index:10;
}

.c-category-card:hover .c-category_overlay {
  background-color: rgba(10, 10, 30, 0.6);
}

.c-category-card_img {
  width: 100%;
  height: 100%;
}

.c-category-card_img-container {
  width: 100%;
  height: 100%;
}

.c-category-bg {
  width: 100%;
  height: 100%;
}

.c-category-card_wrapper{
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-category_overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(10, 10, 30, 0.3);
  -webkit-transition: background-color 200ms ease-out;
  transition: background-color 200ms ease-out;
}

.c-category_title {
  text-align: center;
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-category-card_bg {
  height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 3px 5px 3px rgba(10, 10, 30, 0.05);
}

.c-category-card:hover .is--green{
  height: 100%;
  width: 100%;
  top:-1%;
  left:-1%;
}

.c-category-card:hover .is--red{
  height: 100%;
  width: 100%;
  bottom:1%;
  left:2%;
}

.c-category-card:hover .is--yellow{
  height: 100%;
  width: 100%;
  top:2%;
  right:2%;
}

.c-category-card:hover .is--cyan{
  height: 100%;
  width: 100%;
  top:1%;
  left:-2%;
}

/* Featured Collection List */
.c-categories_grid {
  display: grid;
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

/* card */
.c-card{
  position:relative;
  justify-self: stretch;
}

.c-card:hover .is--green{
  height: 100%;
  width: 100%;
  top:-1%;
  left:-1%;
}

.c-card:hover .is--red{
  height: 100%;
  width: 100%;
  bottom:1%;
  left:2%;
}

.c-card:hover .is--yellow{
  height: 100%;
  width: 100%;
  top:2%;
  right:2%;
}

.c-card:hover .is--cyan{
  height: 100%;
  width: 100%;
  top:1%;
  left:-2%;
}

.c-card_color-bg {
  position: absolute;
  right: 0%;
  bottom: 0%;
  -webkit-filter: blur(5px);
  filter: blur(3px);
  -webkit-transition: height 0ms ease-out, width 0ms ease-out, right 200ms ease-out, bottom 200ms ease-out, left 200ms ease-out, top 200ms ease-out;
  transition: height 0ms ease-out, width 0ms ease-out, right 200ms ease-out, bottom 200ms ease-out, left 200ms ease-out, top 200ms ease-out;
}

.c-card_color-bg.is--green {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #0f0;
}

.c-card_color-bg.is--yellow {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #ff0;
}

.c-card_color-bg.is--red {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: red;
}

.c-card_color-bg.is--cyan {
  z-index: 1;
  width: 0%;
  height: 0%;
  background-color: #0ff;
}

.c-card_bg {
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 3px 5px 3px rgba(10, 10, 30, 0.05);
}

.c-card_img-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-card_overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(10, 10, 30, 0.6);
  opacity: 0;
  -webkit-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}

.c-card_overlay:hover {
  opacity: 1;
}


.c-card_content-container {
  padding: 24px 24px 6px;
}

.c-card_play-container {
  position: absolute;
}

.c-card_details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 0.5px solid #e4e4e4;
  margin-top: 8px;
}

.c-card_title-link {
  margin-bottom: 12px;
  color: #0a0a1e;
  text-decoration: none;
}

.c-card_title-link:hover {
  text-decoration: none;
  color: #0a0a1e;
}

.c-card_detail {
  margin-bottom: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-card_detail.is--last {
  margin-right: 0px;
}

.c-card_detail-info{
  margin-top: auto;
}

.c-card_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.c-card_lists {
  margin-top: 20px;
}

/* button */
.c-btn {
  padding: 12px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 24px;
  -webkit-transition: color 200ms ease-out, background-color 200ms ease-out;
  transition: color 200ms ease-out, background-color 200ms ease-out;
  color: #fff;
}

.c-btn:hover {
  background-color: #fff;
  color: #0a0a1e;
  text-decoration: none;
}

.t-btn {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 1em;
  font-weight: 900;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.c-btn_icon {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.c-btn_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/* member link */
.c-people-link:hover{
  text-decoration: none;
  color: #0a0a1e;
}

.c-people-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  margin-bottom: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0a0a1e;
}

.c-people-link.is--secondary {
  color: #fff;
}


.c-people-link_img-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0.5px;
  border-color: #0a0a1e;
  border-radius: 100%;
}

.c-people-link_img-section.is--secondary {
  border-color: #fff;
}

.c-people-link_img-container {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background-position: 50% 50%;
  background-size: cover;
}

.c-people-link_hover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 72px;
  height: 72px;
  margin: auto;
  border-radius: 100%;
  background-color: #fff;
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.c-people-link_hover:hover{
  opacity: .4;
}

/* people list */
.c-people-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* tag list */
.c-tag-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* section border */
.c-section-border {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-section-border_border {
  width: 100%;
  border-top: 0.5px solid #e4e4e4;
}

/* Scenes */
.c-scenes_container{
  padding-bottom: 18px;
}

/* pill component */

.c-pill {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 8px 16px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #0a0a1e;
  border-radius: 25px;
  -webkit-transition: color 200ms ease-out, background-color 200ms ease-out;
  transition: color 200ms ease-out, background-color 200ms ease-out;
  color: #0a0a1e;
}

.c-pill.is--inline {
  margin-right: 8px;
  margin-bottom: 0px;
  margin-left: 8px;
}

.c-pill:hover {
  background-color: #0a0a1e;
  color: #fff;
  text-decoration: none;
}

.c-pill.is--secondary {

  padding-top: 6px;
  padding-bottom: 6px;
  border-color: #fff;
  color: #fff;
}

.c-pill.is--secondary:hover {
  background-color: #fff;
  color: #0a0a1e;
}

/* navbar component */
.is--navbar-container {
  max-width: none;
}

.c-navbar {
  z-index:100;
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 68px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.c-navbar_group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-navbar_logo-container {
  width: 50px;
  margin-right: 12px;
}

.c-navbar_link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-navbar_link-block:hover {
  text-decoration: none;
}

.c-navbar_link {
  height: 20px;
  max-width: 20px;
  margin-right: 24px;
}

.c-navbar_link.is--last {
  margin-right: 0px;
}

/* navbar opaque*/
.c-navbar.is--nav-opaque{
  position: relative;
}

.t-logo.is--nav-opaque{
  color:#0a0a1e;
}


/* SECTIONS */
/* Members List Large */
.c-members_grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

/* Lucky Button */
.c-lucky {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 400px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 255, 255, 0.3)), color-stop(20%, rgba(41, 255, 255, 0)), color-stop(80%, rgba(229, 32, 255, 0)), to(rgba(255, 0, 255, 0.3))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('../images/lucky_bg.png');
  background-image: linear-gradient(90deg, rgba(0, 255, 255, 0.3), rgba(41, 255, 255, 0) 20%, rgba(229, 32, 255, 0) 80%, rgba(255, 0, 255, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../images/lucky_bg.png');
  background-position: 0px 0px, 0px 0px, 50% 50%;
  background-size: auto, auto, cover;
  background-repeat: repeat, repeat, no-repeat;
  background-attachment: scroll, scroll, fixed;
}

.c-lucky_btn-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-lucky_text {
  margin-bottom: 24px;
  text-align: center;
}

/* Highlight Vids */
.c-highlight-vids_grid {
  display: -ms-grid;
  display: grid;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.c-highlight-vids_title {
  height:32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Feature Video */
.c-feat-vid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 700px;
  padding-top: 68px;
  background-position: 0px 0px, 0px 0px, 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-feat-vid_content-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 720px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.c-feat-vid_content-wrapper {
  padding-left: 16px;
  border-left: 0.5px solid #fff;
}

.c-feat-vid_badge {
  margin-bottom: 12px;
}

.c-feat-vid_title {
  margin-bottom: 24px;
  white-space: normal;
}

/* RESPONSIVE */

@media screen and (max-width: 991px) {
  .c-video-list_grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .c-categories_grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .c-members_grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .c-video-list_grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .l-container {
    margin-right: 24px;
    margin-left: 24px;
  }

  .c-navbar_logo-container {
    width: 36px;
  }

  .t-logo {
    font-size: 20px;
  }

  .l-section {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .l-section.is--bg-gray {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .t-h1 {
    font-size: 36px;
  }

  .t-h3 {
    font-size: 16px;
  }

  .c-pill {
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4px 12px;
  }

  .t-h5 {
    font-size: 12px;
  }

  .c-people-list {
    margin-bottom: 12px;
  }

  .c-people-link {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .c-people-link_img-section {
    width: 60px;
    height: 60px;
  }

  .c-people-link_img-container {
    width: 56px;
    height: 56px;
  }

  .c-people-link_hover {
    width: 56px;
    height: 56px;
  }

  .t-btn {
    font-size: 14px;
  }

  .c-highlight-vids_grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .c-highlight-vids_title {
    margin-bottom: 12px;
  }

  .c-video-card_content-container {
    padding: 12px 12px 4px;
  }

  .c-categories_grid {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .t-h4 {
    font-size: 16px;
  }

  .c-lg-people-link_img-section {
    width: 80px;
    height: 80px;
  }

  .c-lg-people-link_img-container {
    width: 72px;
    height: 72px;
  }
}

@media screen and (max-width: 479px) {
  .l-container {
    margin-right: 12px;
    margin-left: 12px;
  }

  .c-feat-vid {
    min-height: 95vh;
  }

  .t-h1 {
    font-size: 32px;
  }

  .c-categories_grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .c-members_grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
} 