.filter-container {
    border: 1px solid gray;
    border-radius: 20px;
    padding: 5px;
    margin-bottom: 10px;
}

form {
    margin-top: 20px;
}

.hidden-filter-container {
    display: none;
}


.filter-inner {
    background-color: lightgrey;
    border-radius: 200px;
    padding: 2px 15px 5px;
}

.filter-outer {
    border-radius: 200px;
    padding: 1px;
    margin: 0px 1px;
    border: 2px solid rgba(255, 0, 0, 0);
}

.filter-label {
    margin-bottom: 0;
}


.filter-list {
    list-style: none;
    padding-left: 0;
    row-gap: 0.5em;
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter input[type="checkbox"], .filter-inner input[type="checkbox"]   {
    display: none;
 }

.filter-checked {
    border: 2px solid rgba(255, 0, 0, 1);
}

.filter-selected {
    background-color: lightgrey;
    display: inline;
    border-radius: 200px;
    padding: 8px 15px;
    margin: 4px 4px;
}

.filter-selected:hover, .filter-label:hover {
    cursor: pointer;
}

.selected-filters-section {
    display: flex;
    flex-wrap: wrap;
}