.collection-card {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid black;
    background-color: rgba(230, 230, 230, 0.5);
  }

@media (min-width: 370px) {
  .collection-card {
    height: 150px;
    width: 150px;
  }
}

.collection-card:hover {
  background-color: rgba(230, 230, 230, 0.6);
}



.collection-card-link {
  margin: 15px 10px;
  color: black;
  font-weight: bold;
  font-size: 1.2em;
}

.collection-card-link:hover {
  text-decoration: none;
  color: darkslategray;
}

.person-link-lg {
    margin: 15px 10px;
}
