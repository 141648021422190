.home-container {
  padding-top: 20px;
}

.featured-video-home {
  padding-bottom: 20px;
}

.video-card-home {
  padding-top: 10px;
}
